<template>
  <div id="public-statistik-populasi">
    <template v-if="loading">
      <div class="card card-custom card-stretch">
          <!--begin::Header-->
          <div class="card-header h-auto border-0">
              <!--begin::Title-->
              <div class="card-title py-5">
                  <h3 class="card-label">
                      <span class="d-block text-dark font-weight-bolder">Populasi {{ tipe }}</span>
                      <span class="d-block text-muted mt-2 font-size-sm">Existing data populasi {{ tipe }} perstrain</span>
                  </h3>
              </div>
              <div class="card-toolbar">
                <b-button @click="showFilterModal()" size="sm" variant="hover-primary" class="btn-light rounded">
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Text/Filter.svg"/>
                        <!--end::Svg Icon-->
                    </span>
                    <span class="d-none d-sm-inline">Filter</span>
                </b-button>
              </div>
              <!--end::Title-->
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body">
              <ContentLoader></ContentLoader>
          </div>
          <!--end::Body-->
      </div>
    </template>
    <template v-else>
        <PieChart
            title="Populasi GPS"
            deskripsi="Existing data populasi GPS perstrain"
            :setSeries="data.series"
            :categories="data.categories"
        ></PieChart>
    </template>
  </div>
</template>

<script>
import PublicService from "@/core/services/api/statistik/public.service";
import ErrorService from "@/core/services/error.service";

import { ContentLoader } from 'vue-content-loader'
import PieChart from "@/view/content/widgets/chart/Pie.vue"

export default {
  name: "public-statistik-populasi",
  components: {
    ContentLoader,
    PieChart
  },
  props: {
    tipe: {
      type: String,
      default: "GPS",
      required: true
    }
  },
  data() {
    return {
      loading: true,
      data: {
        series: [],
        categories: []
      },
      filter: {
        tahun: (new Date).getFullYear(),
        strain: [],
        tipe: [],
        komoditas: '11040021',
        provinsi: [],
      },
      menufilter: ['tahun', 'strain', 'provinsi']
    }
  },
  methods: {
    getDataSettingHeGps() {
      PublicService.getSettingHeGps()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                this.opsHatchery = []
                return;
            }
            this.opsHatchery = res.data.data
        })
        .catch((err) => {
            this.opsHatchery = []
            ErrorService.status(err)
        })
    },
  },
  mounted() {
    if (this.tipe == "GPS") this.filter.tipe = ['GP']
    if (this.tipe == "PS") this.filter.tipe = ['PS']
    if (this.tipe == "FS") this.filter.tipe = ['FS']
  },
};
</script>