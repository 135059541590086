<template>
  	<div>
        <!--begin::Charts Widget-->
		<div class="card card-custom card-stretch">
			<!--begin::Header-->
			<div v-if="title" class="card-header h-auto border-0">
				<!--begin::Title-->
				<div class="card-title py-5">
					<h3 class="card-label">
						<span class="d-block text-dark font-weight-bolder">{{ title | title }}</span>
						<span class="d-block text-muted mt-2 font-size-sm">{{ deskripsi }}</span>
					</h3>
				</div>
				<!--end::Title-->
			</div>
			<!--end::Header-->
			<!--begin::Body-->
			<div class="card-body">
				<!--begin::Chart-->
				<apexchart
					:options="chartOptions"
					:series="series"
        			type="pie"
				></apexchart>
				<!--end::Chart-->
			</div>
			<!--end::Body-->
		</div>
		<!--end::Charts Widget-->
  	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        title:{
            type: String,
            default: () => "Pie Chart"
        },
        deskripsi:{
            type: String,
            default: () => ""
        },
        setSeries:{
            type: Array,
            default: () => []
        },
        categories:{
            type: Array,
            default: () => []
        },
        colors:{
            type: Array,
            default: () => ["#8576FF", "#00E0D1", "#FEBA6A", "#FE708B", "#EF6AE7", "#6993FF", "#18AFA7", "#8950FC", "#E67300", "#FF475A"]
        },
    },
	data() {
		return {
			chartOptions: {},
			series: [],
		}
	},
	computed: {
		...mapGetters(["layoutConfig"])
	},
	mounted() {
		this.series = this.setSeries

		this.chartOptions = {
			chart: {
				type: 'pie',
                height: 350,
                toolbar: {
                    show: false
                }
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom'
					}
				}
			}],
            labels: this.categories,
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                    fontFamily: this.layoutConfig("font-family")
                },
            },
            colors: this.colors,
            markers: {
                //size: 5,
                //colors: [this.layoutConfig("colors.theme.light.danger")],
                strokeColor: this.layoutConfig("colors.theme.base.info"),
                strokeWidth: 3
            }
        }
	},
}
</script>