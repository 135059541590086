import ApiService from "@/core/services/api.service";

export default {
    // -----API DATA------
    getPopulasiExisting() {
        ApiService.setHeader();
        return ApiService.query("v2/public/statistik/populasi/existing", { params: credentials })
    },
    getPopulasiProduktif() {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/populasi/produktif", { params: credentials })
    },
    getSettingHe() {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/setting-he/perbulan", { params: credentials })
    },
    getProduksiDoc() {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/produksi/perbulan", { params: credentials })
    },
    getDistribusiPerbulan() {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/distribusi/perbulan", { params: credentials })
    },
    getDistribusiPerprovinsi() {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/distribusi/perprovinsi", { params: credentials })
    },
    getDistribusiIntEks() {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/distribusi/int-eks", { params: credentials })
    },
}